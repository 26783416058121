import React, { useState } from 'react';
import { Navigation, StyleGuide } from '@kaes/react-components';
import { BrowserRouter, NavLink, Route, Switch } from 'react-router-dom';
import { PageNotFound } from './pages/PageNotFound';
import Home from './pages/Home';

function App(): JSX.Element {
  const [open, toggle] = useState(false);

  return (
    <BrowserRouter>
      <Navigation
        imgURL="https://images.techdecaf.com/fit-in/200x/techdecaf/cgen_logo.png"
        height={50}
        color="white"
        bg="dark"
        className="text-capitalize"
        open={open}
        toggle={toggle}
      >
        <NavLink to="/style-guide" className="mx-2">
          style-guide
        </NavLink>
      </Navigation>

      {/* <StyleGuide></StyleGuide> */}

      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/style-guide" component={StyleGuide} />
        <Route component={PageNotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
