import React from 'react';

export const PageNotFound = (): JSX.Element => (
  <div className="container">
    <div className="row">
      <div className="col-md-12 mt-5">
        <h1 className="text-center">HTTP Status Code 404 - Not Found</h1>
      </div>
    </div>
  </div>
);
